import React, { lazy, useContext } from "react";
import * as ROUTE from "./routesConstant";
import { Routes, Route, Navigate } from "react-router-dom";
import PublicLayout from "../components/Layout/PublicLayout";
import PrivateLayout from "../components/Layout/PrivateLayout";
import Loader from "../components/Loader";
import AccountDetailsPage from "../pages/FundManagement/AccountDetailsPage";
import Result from "../pages/Payments/Result";
import { AuthContext } from "../contexts/AuthContext";

const SignIn = lazy(() => import("../pages/SignIn/SignIn"));
const PersonalInfo = lazy(() => import("../pages/PersonalInfo/PersonalInfo"));
const SignUp = lazy(() => import("../pages/SignUp/SignUp"));
const SignupSuccess = lazy(() =>
  import("../pages/SignupSuccess/SignupSuccess")
);
const VerifyEmail = lazy(() => import("../pages/VerifyEmail/VerifyEmail"));
const ResetPass = lazy(() => import("../pages/ResetPass/ResetPass"));
// start
const ResetPasswordLink = lazy(() =>
  import("../pages/RestPassword/SendResetPasswordLink")
);
const CreateNewPassword = lazy(() =>
  import("../pages/RestPassword/ResetPassword")
);
const UpdatePassword = lazy(() =>
  import("../pages/RestPassword/EnterSecurityCode")
);
// end
const Pagenotfound = lazy(() => import("../pages/PageNotFound/PageNotFound"));
const ForgetPassword = lazy(() =>
  import("../pages/ForgetPassword/ForgetPassword")
);
const Home = lazy(() => import("../pages/SignIn/SignIn"));
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const AdminDashboard = lazy(() => import("../pages/Dashboard-admin/index"));
const UserDashboard = lazy(() => import("../pages/Dashboard-user/index"));
const FundManagement = lazy(() =>
  import("../pages/FundManagement/FundManagement")
);
const FundManagementTranx = lazy(() =>
  import("../pages/FundManagement/FundManagementTranx")
);
const FundManagementIdentity = lazy(() =>
  import("../pages/FundManagement/FundManagementIdentity")
);

const PaymentsUser = lazy(() => import("../pages/Payments/User"));
const PaymentsAdmin = lazy(() => import("../pages/Payments/Admin"));
const PaymentsAdminQueued = lazy(() =>
  import("../pages/Payments/QueuedPayments")
);
const PaymentsUserCustomerDetails = lazy(() =>
  import("../pages/Payments/CustomerDetails")
);

const ApprovalRules = lazy(() => import("../pages/Approval/ApprovalRules"));
const ApprovalTranx = lazy(() => import("../pages/Approval/ApprovalTranx"));
const NewApproval = lazy(() => import("../pages/Approval/NewApproval"));
const ApprovalSuccess = lazy(() => import("../pages/Approval/ApprovalSuccess"));
const EmployerList = lazy(() => import("../pages/Employer/EmployerList"));
const EmployeeTranxDetail = lazy(() => import("../pages/Employer/EmployeeTranxDetail"));
const UsersList = lazy(() => import("../pages/Users/UsersList"));

const AcceptInvitation = lazy(() =>
  import("../pages/Employer/AcceptInvitation")
);

const ANB = lazy(() => import("../pages/anb/anb"));
const ANBTransactionList = lazy(() =>
  import("../components/anb/TransactionList")
);
// Subscriptions for Users components
const SubscriptionHome = lazy(() =>
  import("../pages/Subscriptions/SubscriptionHome")
);
const AddCard = lazy(() => import("../pages/Subscriptions/AddCard"));
const AddCardRedirect = lazy(() =>
  import("../pages/Subscriptions/AddCardRedirect")
);
const SubscriptionPlans = lazy(() => import("../pages/Subscriptions/Plans"));
const Checkout = lazy(() => import("../pages/Subscriptions/Checkout"));
const CheckoutRedirect = lazy(() =>
  import("../pages/Subscriptions/CheckoutRedirect")
);

// All Cards
const AllCards = lazy(() => import("../pages/CardManagement/AllCards"));
const ManageCard = lazy(() => import("../pages/CardManagement/ManageCard"));

const ListCardsOwner = lazy(() => import("../pages/Cards/Cards"));

const Setting = lazy(() => import("../pages/Setting/Setting"));

const Transactions = lazy(() => import("../pages/Transactions/Transactions"));
const TransactionDetail = lazy(() => import("../pages/Transactions/TransactionDetail"));
const Notifications = lazy(() => import("../pages/Notifications/Notifications"));
const Requests = lazy(() => import("../pages/Notifications/Requests"));

const Router = () => {
  const auth = useContext(AuthContext);

  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<PrivateLayout />}>
          {(auth?.user?.role === "OWNER" || auth?.user?.role === "MANAGER") ? (
            <>
              <Route path={ROUTE.DASHBOARD} element={<Dashboard />} />
              <Route
                path={ROUTE.FUND_MANAGEMENT}
                element={<FundManagement />}
              />
              <Route
                path={ROUTE.FUND_MANAGEMENT_TRANX}
                element={<AccountDetailsPage />}
              />
              <Route
                path={ROUTE.FUND_MANAGEMENT_IDENTITY}
                element={<FundManagementIdentity />}
              />

              <Route path={ROUTE.APPROVAL_RULES} element={<ApprovalRules />} />
              <Route path={ROUTE.APPROVAL_TRANX} element={<ApprovalTranx />} />
              <Route path={ROUTE.NEW_APPROVAL} element={<NewApproval />} />
              <Route
                path={ROUTE.APPROVAL_SUCCESS}
                element={<ApprovalSuccess />}
              />
              <Route path={ROUTE.EMPLOYER_LIST} element={<EmployerList />} />
              <Route path={ROUTE.EMPLOYEE_TRANX_LIST} element={<EmployeeTranxDetail />} />
              <Route path={ROUTE.ANB_ROUTE} element={<ANB />} />
              <Route
                path={ROUTE.ANB_TRANSACTIONS}
                element={<ANBTransactionList />}
              />
              <Route path={ROUTE.USER_LIST} element={<UsersList />} />

            {/* Subscriptions user Routes starts */}
              <Route
                path={ROUTE.SUBSCRIPTIONS_HOME_USER}
                element={<SubscriptionHome />}
              />
              <Route
                path={ROUTE.SUBSCRIPTIONS_USER_ADD_CARD}
                element={<AddCard />}
              />
              <Route
                path={ROUTE.SUBSCRIPTIONS_USER_ADD_CARD_REDIRECT_URL}
                element={<AddCardRedirect />}
              />
              <Route
                path={ROUTE.SUBSCRIPTIONS_USER_PLANS}
                element={<SubscriptionPlans />}
              />
              <Route
                path={ROUTE.SUBSCRIPTIONS_USER_CHECKOUT}
                element={<Checkout />}
              />
              <Route
                path={ROUTE.SUBSCRIPTIONS_USER_CHECKOUT_REDIRECT_URL}
                element={<CheckoutRedirect />}
              />

              {/* Card Management */}
              <Route path={ROUTE.ALLCARDS} element={<AllCards />} />
              <Route path={ROUTE.MANAGECARD} element={<ManageCard />} />
              <Route path={ROUTE.LIST_CARDS} element={<ListCardsOwner />} />

              <Route path={ROUTE.TRANSACTIONS} element={<Transactions />} />
              <Route path={ROUTE.TRANSACTIONS_DETAIL} element={<TransactionDetail />} />
              <Route path={ROUTE.SETTING} element={<Setting />} />
              <Route path={ROUTE.NOTIFICATIONS} element={<Notifications />} />
              <Route path={ROUTE.REQUESTS} element={<Requests />} />
              { auth?.user?.role === "OWNER" && (
                <>
                  <Route path={ROUTE.PAYMENTS_ADMIN} element={<PaymentsAdmin />} />
                  <Route
                  path={ROUTE.PAYMENTS_ADMIN_QUEUED}
                  element={<PaymentsAdminQueued />}
                  />
                </>
              )}
              <Route path={ROUTE.PAYMENTS_USER} element={<PaymentsUser />} />
              <Route
              path={ROUTE.PAYMENTS_USER_CUSTOMER_DETAILS}
              element={<PaymentsUserCustomerDetails />}
              />
            </>
          ) : auth?.user?.role === "USER" ? (
            <>
              <Route path={ROUTE.DASHBOARD} element={<UserDashboard />} />
            </>
          ): auth?.user?.role === "ADMIN" ? (
            <>
              <Route path={ROUTE.DASHBOARD} element={<AdminDashboard />} />
            </>
          ) : null}
          <Route path="*" element={<Pagenotfound />} />
        </Route>
        <Route element={<PublicLayout />}>
          <Route path={ROUTE.HOME} element={<Home />} />
          <Route path={ROUTE.PERSONAL_INFO} element={<PersonalInfo />} />
          <Route path={ROUTE.SIGN_IN} element={<SignIn />} />
          <Route path={ROUTE.SIGN_UP} element={<SignUp />} />
          <Route path={ROUTE.SIGN_UP_SUCCESS} element={<SignupSuccess />} />
          <Route path={ROUTE.VERIFYEMAIL} element={<VerifyEmail />} />
          <Route path={ROUTE.VERIFYEMAIL} element={<ResetPass />} />
          <Route path={ROUTE.RESETPASSWORD} element={<ResetPass />} />
          <Route path={ROUTE.FORGETPASS} element={<ForgetPassword />} />
          <Route
            path={ROUTE.RESET_PASSWORD_LINK}
            element={<ResetPasswordLink />}
          />
          <Route
            path={ROUTE.CREATE_NEW_PASSWORD}
            element={<CreateNewPassword />}
          />
          <Route path={ROUTE.UPDATE_PASSWORD} element={<UpdatePassword />} />
          <Route
            path={ROUTE.ACCEPT_INVITATION}
            element={<AcceptInvitation />}
          />
          <Route path="*" element={<Pagenotfound />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default Router;
