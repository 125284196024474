import { all, put, takeLatest } from "redux-saga/effects";
import {
  GET_CHECKOUT_ID,
  TOKEN_GENERATION,
  GET_ALL_CARD_INFO,
  DELETE_CARD_INFO,
  GET_ONECLICK_CHECKOUT_ID,
  GET_PAYMENT_STATUS,
  GET_SUBSCRIPTION_HISTORY,
  CANCEL_ACTIVE_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLAN,
} from "../actions/subscriptions.actions.types";
import { subscriptionSlice } from "../slices/subscriptions";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* getCheckoutId({ payload: { callback } }) {
  const payload = {
    method: "get",
    url: "/subscription/checkout-init",
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* tokenGeneration({ payload: { callback, checkoutId } }) {
  const data = { checkoutId };
  const payload = {
    data,
    method: "post",
    url: "/subscription/token-generation",
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* getAllCardInfo({ payload: { callback } }) {
  const payload = {
    method: "get",
    url: "/subscription/cards",
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* deleteCardInfo({ payload: { callback, id } }) {
  const payload = {
    method: "delete",
    url: `/subscription/cards/${id}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* getOneClickCheckoutId({ payload: { callback, planId } }) {
  const payload = {
    method: "get",
    url: `/subscription/checkout-init-oneClick?planId=${planId}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      yield put(subscriptionSlice.actions.setPlan({ planId }));
      callback({ success: true, data: result.data });
    }
  }
}

function* getPaymentStatus({ payload: { callback, query } }) {
  const payload = {
    method: "get",
    url: `/subscription/payment?resourcePath=${query.resource}&planId=${query.planId}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* getSubscriptionHistory({ payload: { callback } }) {
  const payload = {
    method: "get",
    url: `/subscription/payment/all?is_active=true`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* cancelActiveSubscription({ payload: { callback, id } }) {
  const payload = {
    method: "put",
    url: `/subscription/${id}`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* getSubscriptionPlan({ payload: { callback } }) {
  const payload = {
    method: "get",
    url: `/subscription/plans`,
  };
  const { error, result } = yield call(httpClient, { payload });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* Subscription() {
  yield all([takeLatest(GET_CHECKOUT_ID, getCheckoutId)]);
  yield all([takeLatest(TOKEN_GENERATION, tokenGeneration)]);
  yield all([takeLatest(GET_ALL_CARD_INFO, getAllCardInfo)]);
  yield all([takeLatest(DELETE_CARD_INFO, deleteCardInfo)]);
  yield all([takeLatest(GET_ONECLICK_CHECKOUT_ID, getOneClickCheckoutId)]);
  yield all([takeLatest(GET_PAYMENT_STATUS, getPaymentStatus)]);
  yield all([takeLatest(GET_SUBSCRIPTION_HISTORY, getSubscriptionHistory)]);
  yield all([takeLatest(CANCEL_ACTIVE_SUBSCRIPTION, cancelActiveSubscription)]);
  yield all([takeLatest(GET_SUBSCRIPTION_PLAN, getSubscriptionPlan)]);
}

export default Subscription;
