import io from "socket.io-client";

const SOCKET_URL = "ws://inflow-api.development.limited";


export const socket =(token)=> {
  if(token) {
    return io.connect(SOCKET_URL, {
      transports: ["websocket"],
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
  }
};
