//Common routes
export const HOME = "/";
export const PROFILE = "/profile";
export const SIGN_IN = "/login";
export const PERSONAL_INFO = "/register";
export const SIGN_UP = "/signup";
export const SIGN_UP_SUCCESS = "/signup-success";
export const VERIFYEMAIL = "/verify/:email/:token";
export const RESETPASSWORD = "/reset/:email/:token";
export const FORGETPASS = "/forgetpass";
export const DASHBOARD = "/dashboard";
export const RESET_PASSWORD_LINK = "/reset-password-link";
export const CREATE_NEW_PASSWORD = "/create-new-password";
export const UPDATE_PASSWORD = "/update-password";

// >>>>>>>>>>>>>>>>>
// ROLE: OWNERS (Business owners)
// >>>>>>>>>>>>>>>>>
// BFM - Business Finance management
export const FUND_MANAGEMENT = "/fund-management";
export const FUND_MANAGEMENT_TRANX = "/fund-management-tranx";
export const FUND_MANAGEMENT_IDENTITY = "/fund-management-identity";
export const PAYMENTS_ADMIN = "/payments/admin";
export const PAYMENTS_ADMIN_QUEUED = "/payments/admin/customer/:id";

// Aprovals rule
export const APPROVAL_RULES = "/approval-rules";
export const APPROVAL_TRANX = "/approval-tranx";
export const NEW_APPROVAL = "/new-approval";
export const APPROVAL_SUCCESS = "/approval-success";

// employers
export const EMPLOYER_LIST = "/employers";
export const EMPLOYEE_TRANX_LIST = "/employers/employee-transaction-detail/:id";
export const ACCEPT_INVITATION = "/accept-invite";

//users
export const USER_LIST = "/users";

//anb

export const ANB_ROUTE = "/anb";
export const ANB_TRANSACTIONS = "/anb/transactions/:accountNumber";

// Subscriptions

export const SUBSCRIPTIONS_HOME_USER = "/subscriptions/user";
export const SUBSCRIPTIONS_USER_ADD_CARD = "/subscriptions/user/add-card";
export const SUBSCRIPTIONS_USER_ADD_CARD_REDIRECT_URL =
  "/subscriptions/user/add-card/redirect";
export const SUBSCRIPTIONS_USER_PLANS = "/subscriptions/plans";
export const SUBSCRIPTIONS_USER_CHECKOUT = "/subscriptions/user/checkout";
export const SUBSCRIPTIONS_USER_CHECKOUT_REDIRECT_URL =
  "/subscriptions/user/checkout/redirect";

// Transactions
export const TRANSACTIONS = "/transactions"
export const TRANSACTIONS_DETAIL = "//transactions/transaction-detail/:id"


// Card Management

export const ALLCARDS = "/cards/admin/allCards";
export const MANAGECARD = "/cards/admin/manage-cards";

export  const SETTING ="/setting";

// >>>>>>>>>>>>>>>>>
// ROLE: USERS (Business users)
// >>>>>>>>>>>>>>>>>


export const LIST_CARDS = "/cards/owner/cards"



//BFM
export const PAYMENTS_USER = "/payments/user";
export const PAYMENTS_USER_CUSTOMER_DETAILS = "/payments/user/customer/:id";

export const NOTIFICATIONS = "/notifications";
export const REQUESTS = "/requests";

// >>>>>>>>>>>>>>>>>
// ROLE: ADMIN (APP Admins)
// >>>>>>>>>>>>>>>>>
