import { createSlice } from "@reduxjs/toolkit";
const initialState = { employeeList: [] };

export const employerSlice = createSlice({
  name: "employer",
  initialState,
  reducers: {
    employerList: (state = initialState, { payload }) => {
      return {
        ...state,
        employeeList: payload,
      };
    },
    deleteEmployee: (state = initialState, { payload }) => {
      return {
        ...state,
        employeeList: {users: state.employeeList.users.filter(el=>el.id!==payload.id)},
      };
    },
  },
  extraReducers: {},
});
