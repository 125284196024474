import { all, takeLatest, put } from "redux-saga/effects";
import { BANK_TRANSACTION, TRANSACTION_RECEIPT } from "../actions/transaction.actions.types";
import { transactionSlice } from "../slices/transaction";
import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* bankTransaction({ payload: { data, size, include_billed_by, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/paytabs/card/transactions?include_status=true&size=${size}&include_billed_by=${include_billed_by}`,
  };
  const { error, result } = yield call(httpClient, { payload, isLoader: true, authorization: true, });
  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      yield put(transactionSlice.actions.setTransactions(result.data));
      callback(result);
    }
  }
}
function* transactionReceipt({payload: { transactionId, callback }}) {
  const payload = {
    method: "get",
    url: `/paytabs/transaction-receipt/${transactionId}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });
  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      yield put(transactionSlice.actions.setTransactionReceipt(result.data));
      callback(result);
    }
  }
}

function* Transaction() {
  yield all([takeLatest(BANK_TRANSACTION, bankTransaction)]);
  yield all([takeLatest(TRANSACTION_RECEIPT, transactionReceipt)]);
}

export default Transaction;
