import React, { useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Navigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { errorMessage } from "../../utilities/notification";
import { tranxDetail } from "../../stores/actions/bfm.actions.type";
import TranxTable from "../../components/tables/TranxTable";
import Loader from "../../components/Loader";
import FundManagementTranx from "./FundManagementTranx";
import FundManagementIdentity from "./FundManagementIdentity";
import FundManageMentInsights from "./FundManageMentInsights";
const { PUBLIC_URL } = process.env;

const AccountDetails = (props) => {
  const [selected, setSelected] = useState(0);
  return (
    <AuthContext.Consumer>
      {(auth) => {
        if (!auth) {
          return <Navigate to="/login" />;
        }
        return (
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex flex-column mt-2 pt-2 applicationStatusDashLeftCol">
                  <ul
                    className="nav nav-pills mb-3 managementTabs"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setSelected(0)}
                        className={`nav-link ${selected === 0 && "active"}`}
                        id="pills-transactions-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-transactions"
                        type="button"
                        role="tab"
                        aria-controls="pills-transactions"
                        aria-selected="true"
                      >
                        Transactions
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setSelected(1)}
                        className={`nav-link ${selected === 1 && "active"}`}
                        id="pills-identity-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-identity"
                        type="button"
                        role="tab"
                        aria-controls="pills-identity"
                        aria-selected="false"
                      >
                        Identity
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setSelected(2)}
                        className={`nav-link ${selected === 2 && "active"}`}
                        // className="nav-link"
                        id="pills-insights-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-insights"
                        type="button"
                        role="tab"
                        aria-controls="pills-insights"
                        aria-selected="false"
                      >
                        Insights
                      </button>
                    </li>
                  </ul>
                  {selected === 0 ? (
                    <FundManagementTranx />
                  ) : selected === 1 ? (
                    <FundManagementIdentity />
                  ) : selected === 2 ? (
                    <FundManageMentInsights />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </AuthContext.Consumer>
  );
};

const mapStateToProps = (state) => ({
  visible: state.app.visible,
});

export default connect(mapStateToProps, { tranxDetail })(AccountDetails);
