import React from "react";
import { sortBy, filter, map } from "underscore";
function MostSpendSection({ list }) {
  return (
    <>
      {list.slice(0, 3).map((el, i) => (
        <div className="spenMostRows d-flex justify-content-between" key={i}>
          <div className="spentProfileCol d-flex">
            <div style={{ marginLeft: "13x" }}>
              <h6 className="purchaseTitle mb-2 text-primary">{el.key}</h6>
              <p className="purchaseCaption mb-0">Total {el.count} Payments</p>
            </div>
          </div>
          <div className="spentRightCol">
            <p className="purchaseCaption text-end mb-1">Total spent</p>
            <h6 className="text-danger fw-bold mb-0">
              {el.type === "cr" ? "" : "-"} {el.amount.toFixed(2)} {el.currency}
            </h6>
          </div>
        </div>
      ))}
    </>
  );
}

export default MostSpendSection;
