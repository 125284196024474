import { createAction } from "redux-actions";

export const GET_CHECKOUT_ID = "GET_CHECKOUT_ID";
export const getCheckoutId = createAction(GET_CHECKOUT_ID);

export const TOKEN_GENERATION = "TOKEN_GENERATION";
export const tokenGeneration = createAction(TOKEN_GENERATION);

export const GET_ALL_CARD_INFO = "GET_ALL_CARD_INFO";
export const getAllCardInfo = createAction(GET_ALL_CARD_INFO);

export const DELETE_CARD_INFO = "DELETE_CARD_INFO";
export const deleteCardInfo = createAction(DELETE_CARD_INFO);

export const GET_ONECLICK_CHECKOUT_ID = "GET_ONECLICK_CHECKOUT_ID";
export const getOneClickCheckoutId = createAction(GET_ONECLICK_CHECKOUT_ID);

export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS";
export const getPaymentStatus = createAction(GET_PAYMENT_STATUS);

export const GET_SUBSCRIPTION_HISTORY = "GET_SUBSCRIPTION_HISTORY";
export const getSubscriptionHistory = createAction(GET_SUBSCRIPTION_HISTORY);

export const CANCEL_ACTIVE_SUBSCRIPTION = "CANCEL_ACTIVE_SUBSCRIPTION";
export const cancelActiveSubscription = createAction(CANCEL_ACTIVE_SUBSCRIPTION);

export const GET_SUBSCRIPTION_PLAN = "GET_SUBSCRIPTION_PLAN";
export const getSubscriptionPlan = createAction(GET_SUBSCRIPTION_PLAN);