import React, { useContext } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom"

import AuthHeader from "../AuthHeader";
//import Footer from "../Footer";
import { Navigate, Outlet } from "react-router-dom";
import * as ROUTE from "../../../routes/routesConstant";
import { AuthContext } from "../../../contexts/AuthContext";
import SidebarAdmin from "../Sidebar/Sidebar.Admin";
import SidebarOwner from "../Sidebar/Sidebar.Owner";
import SidebarUser from "../Sidebar/Sidebar.User";

const sidebarTable = {
  OWNER: SidebarOwner,
  MANAGER: SidebarOwner,
  USER: SidebarUser,
  ADMIN: SidebarAdmin,
};
const PrivateLayout = (props) => {
  const location = useLocation();

  const auth = useContext(AuthContext);

  if (!auth) {
    return <Navigate to={{ pathname: ROUTE.SIGN_IN }} />;
  }
  if(props?.applicationStatus?.status !=="APPROVED" && location.pathname !== "/dashboard") {
    return <Navigate to={{pathname: ROUTE.DASHBOARD}}/>;
  }
  const Sidebar = sidebarTable[auth?.user?.role || "OWNER"];
  return (
    <React.Fragment>
      <div id="wrapper">
        <Sidebar role={auth?.user?.role} />
        <div className="mainContentWrapper">
          <AuthHeader />
          <div className="dash-cnt">
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
    applicationStatus: state?.business?.applicationStatus,
  });
export default connect(mapStateToProps)(PrivateLayout);
